<template>
  <div>
    <b-card-actions
      :show-loading="saving || fetching"
      action-collapse
      action-refresh
      @refresh="getData"
    >
      <e-filters
        :search-text="$t('Filtrar')"
        :searching="fetching"
        :title="$t('Filtrar Antecipações')"
        class="mb-2 card-bordered"
        @search="getData"
        @reset="resetFiltersLocal"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="antecipation_filter-value"
              v-model="antecipationFilters.value"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor Líquido')"
              :placeholder="$t('Valor')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="antecipation_filter-acquirer_product_id"
              v-model="antecipationFilters.acquirerProductId"
              type="vue-select"
              :options="getnetAcquirerProductsOptions"
              :label="$t('Forma de pagamento Getnet')"
              :placeholder="$t('Selecione')"
            />
          </b-col>
        </b-row>
      </e-filters>

      <b-table
        ref="antecipation-items-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('ANTECIPATION_ITEM.NAME'), 'female')"
        :fields="fields"
        :items="antecipationItems"
      >
        <template #cell(operationDate)="row">
          {{ row.item.operationDate | date('-') }}
        </template>

        <template #cell(operationCreditDate)="row">
          {{ row.item.operationCreditDate | date('-') }}
        </template>

        <template #cell(references)="row">
          <e-router-link
            v-if="row.item.accountPayableId"
            :title="$t('Visualizar despesa')"
            :to="{
              name: 'expense-maintain',
              params: { id: row.item.accountPayableId },
            }"
            :value="row.item.accountPayableId"
          />
        </template>

        <template #custom-foot>
          <tr>
            <th colspan="3" />
            <th class="text-right">
              {{ getnetTotalGrossValue | currency }}
            </th>
            <th class="text-right">
              {{ getnetTotalNetValue | currency }}
            </th>
            <th class="text-right">
              {{ erpTotalTaxDiscountValue | currency }}
            </th>
            <th colspan="3" />
          </tr>
        </template>
      </b-table>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// import EButton from '@/views/components/EButton.vue'
import { reconciliationDomains } from '@/mixins'
import { EFilters } from '@/views/components'
import ERouterLink from '@/views/components/ERouterLink.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardActions,
    EFilters,
    ERouterLink,
  },

  mixins: [reconciliationDomains],

  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('pages/financial/reconciliation/getnet/maintain', [
      'antecipationItems',
      'sorting',
      'antecipationFilters',
      'antecipationSummaryFields',
    ]),
    ...mapGetters('pages/financial/reconciliation/getnet', ['getnetAcquirerProductsOptions']),

    getnetTotalGrossValue() {
      return this.antecipationSummaryFields?.getnetTotalGrossValue?.value
    },
    getnetTotalNetValue() {
      return this.antecipationSummaryFields?.getnetTotalNetValue?.value
    },
    erpTotalTaxDiscountValue() {
      return this.antecipationSummaryFields?.getnetTotalTaxDiscountValue?.value
    },

    fields() {
      return [
        {
          label: this.$t('Data Transação'),
          key: 'operationDate',
          sortKey: 'operationDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Data Pagamento'),
          key: 'operationCreditDate',
          sortKey: 'operationCreditDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Número Operação'),
          key: 'operationNumber',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Valor bruto'),
          key: 'grossValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Valor líqui.'),
          key: 'netValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Comissão'),
          key: 'operationCost',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Forma'),
          key: 'acquirerProduct.name',
          tdClass: 'text-center',
          // thStyle: { minWidth: '130px', maxWidth: '150px' },
          sortable: true,
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', ['resetAntecipationFilters']),

    getData() {
      this.$emit('get-data')
    },

    resetFiltersLocal() {
      this.resetAntecipationFilters()
      this.getData()
    },
  },
}
</script>

<style></style>
